import mitt from 'mitt'

import { Confirm } from '@/types/general'

export type Events = {
    fetchTransactions: void
    fetchReceipts: void
    fetchDriverReports: void
    fetchDamageReports: void
    incidentFixed: void
    showConfirm: Confirm
    fetchAlarms: void
    fetchShipments: void
    fetchTickets: void
    fetchDeviations: void
}

// Global event bus to emit events across the entire application
export const emitter = mitt<Events>()
